import React, { useContext, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext, AnalyticsContext, TempContext } from "context";
import { PrimaryTemplate as Layout } from "Layout/Templates";
import { MaxWidthContainer } from "Layout/MaxWidthContainer";
import {
  Modal,
  Media16By9,
  MediaVideo,
  HeroVideo,
  ButtonPrimary,
} from "Components";
import { RegistrationForm } from "Components/Form";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "Components/Assets/logo.png";
import { useMediaQuery } from "hooks";
import { VideoPlayer } from "Components";
import Aos from "aos";
import "aos/dist/aos.css";
import {
  motion,
  useInView,
  useAnimation,
} from "framer-motion/dist/framer-motion";
import { AnimatePop } from "./AnimatePop";
import { AnimateDown } from "./AnimateDown";
import { AnimateSlideRight } from "./AnimateSlideRight";
import { AnimateSlideLeft } from "./AnimateSlideLeft";
import { AnimateRotate } from "./AnimateRotate";
import { CookieButton } from "Layout";
import imgHeroMob from "Components/img/heroMob.png";
import imgHero from "Components/img/hero.png";
import Leaf1 from "Components/img/Leaf1.png";
import Leaf2Reverse from "Components/img/Leaf2-reverse.png";
import Starfish2 from "Components/img/Starfish2.png";

export const RegistrationLayout = React.memo(
  ({
    sweeps,
    postSweeps,
    ineligible,
    formStep = 0,
    registered,
    postCampaign,
    setIneligible,
  }) => {
    const { event } = useContext(EventContext);
    const { logVideoAction, logClick } = useContext(AnalyticsContext);

    const [open, setOpen] = useState(false);
    const [video, setVideo] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const downloadButton = useRef(null);
    const setModalVisible = (bool, link) => {
      setVideo(link);
      setOpen(bool);
      logVideoAction({ bool, videoLink: link, videoTitle: "Hero Video" });
    };
    const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
    const storageDirectory = event?.meta?.storageDirectory;

    const isMobile = useMediaQuery();
    // logo
    const { brand: brandLogo, hero, link } = event?.logos;
    let brandLogoImg = `${api}/o/${storageDirectory}%2F${brandLogo?.image}?alt=media`;
    //  let heroImg = `${api}/o/${storageDirectory}%2F${hero?.image}?alt=media`;
    if (registered) formStep = event?.register.sweeps.length - 1;

    const scrollToSection = (elementRef) => {
      elementRef.current.scrollIntoView();
    };

    const {
      footerHeading,
      maxPolicy,
      brandDisclaimer,
      downloadLink,
      privacyPolicy,
    } = event.footer;

    // registration content
    const {
      heading,
      subHeading,
      subHeadingPostReg,
      subBodyPostSweep,
      body,
      bodyPostSweep,
      bodyPostReg,
      formHeading,
      formLegal,
      submit,
      pageCheck,
    } = sweeps
      ? event?.register.sweeps[formStep]
      : event?.register.postSweeps[formStep];

    // ineligible content
    const {
      heading: ineligibleHeading,
      body: ineligibleBody,
    } = event?.register;

    // music video background
    const {
      background: musicVideoBg,
      label: musicVideoLabel,
      video: musicVideoLink,
    } = event?.callToActions.musicVideo;
    let videoBgDesktop = `${api}/o/${storageDirectory}%2F${musicVideoBg.desktop}?alt=media`;
    let videoBgMobile = `${api}/o/${storageDirectory}%2F${musicVideoBg.mobile}?alt=media`;

    // brand cta
    const {
      btnLink: brandCTALink,
      btnText: brandCTAText,
      heading: brandCTAHeading,
    } = event?.callToActions.brand;

    // brand cta background
    const {
      desktop: brandBgD,
      mobile: brandBgM,
    } = event?.callToActions.brand.background;
    let brandBgDesktop = `${api}/o/${storageDirectory}%2F${brandBgD}?alt=media`;
    let brandBgMobile = `${api}/o/${storageDirectory}%2F${brandBgM}?alt=media`;
    // brand product background
    const {
      desktop: brandProductDesktop,
      mobile: brandProductMobile,
    } = event?.callToActions.brand.product;
    let productDesktop = `${api}/o/${storageDirectory}%2F${brandProductDesktop}?alt=media`;
    let productMobile = `${api}/o/${storageDirectory}%2F${brandProductMobile}?alt=media`;

    // post-campaign content
    const {
      heading: postCampaignHeading,
      headingMobile: postCampaignHeadingMobile,
      subHeading: postCampaignSubHeading,
      body: postCampaignBody,
      heroImage,
    } = event?.postCampaign;
    let heroImg = `${api}/${storageDirectory}/${heroImage}?alt=media`;

    // brand cta (post-campaign)
    const {
      btnLink: brandSecondaryCTALink,
      btnText: brandSecondaryCTAText,
      heading: brandSecondaryCTAHeading,
    } = event?.callToActions.brandSecondary;

    const { video: heroVideo } = event?.callToActions?.heroVideo;
    // const desktopVideoUrls = "https://storage.googleapis.com/whitelabel-set-live.appspot.com/632-mcdonalds-los-parras/desktop/manifest.m3u8"
    // const desktopVideoUrls = desktop?.map(
    //   (url) => `${api}/${storageDirectory}/${url}`
    // );
    //  const desktopVideoUrls = `${api}/${storageDirectory}/${desktop[0]}`;
    //  const mobileVideoUrls = `${api}/${storageDirectory}/${mobile[0]}`;

    //   console.log("URL",desktopVideoUrls)
    // const mobileVideoUrls = mobile?.map(
    //   (url) => `${api}/o/${storageDirectory}%2F${url}?alt=media`
    // );

    //  const currentVideoUrl = isMobile ? desktopVideoUrls : mobileVideoUrls;

    const linkClickHandler = (url, label, category) => {
      logClick({ label, url }, category);
    };

    // if (!loaded) {
    //   return null;
    // }
    console.log("load");
    return (
      <Layout
        registration={postCampaign ? false : true}
        flexGrow
        //  showFooter
        registered={registered}
        postCampaign={postCampaign}
      >
        <Container>
          <Content>
            <CTA>
              <AnimateWrapper>
                {!isMobile && (
                  <AnimateRotate left={true}>
                    <ItemsImg
                      style={{ width: 170 }}
                      src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/678-mcdonalds-dannylux/Starfish1.png"
                      alt="sunglasses and other beach things"
                    />
                  </AnimateRotate>
                )}
                <AnimateDown>
                  <ItemsImg
                    style={{ width: isMobile ? 260 : 300 }}
                    // src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/678-mcdonalds-dannylux/Leaf1.png"
                    src={Leaf1}
                    alt="sunglasses and other beach things"
                  />
                </AnimateDown>
              </AnimateWrapper>
              {!loaded ? (
                <div
                  style={{
                    height: 650,
                    width: "100vw",
                    backgroundColor: "#fed82e",
                  }}
                ></div>
              ) : (
                <CTAContent>
                  <AnimatePop>
                    <LogoBox>
                      <StyledLink
                        href={brandLogo.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() =>
                          linkClickHandler(
                            brandLogo.link,
                            "McDonald’s Homepage",
                            "header"
                          )
                        }
                      >
                        {brandLogoImg && <Logo src={logo} />}
                      </StyledLink>
                    </LogoBox>
                  </AnimatePop>
                  <AnimatePop>
                    <Wrapper>
                      <Heading style={{ textAlign: "center" }}>
                        Sumérgete en el <br /> verano con DannyLux
                      </Heading>
                      <Body style={{ margin: "auto" }}>
                        Desde tocar la guitarra hasta refrescarse con un Frozen
                        de McDonald's, DannyLux está en un trayecto musical como
                        ninguno. Mira el video a continuación.
                      </Body>
                      <StyledButton
                        onClick={() => {
                          setModalVisible(true, heroVideo);
                          linkClickHandler(
                            heroVideo,
                            "Hero Video",
                            "internal_link"
                          );
                        }}
                      >
                        Ver Video
                      </StyledButton>
                    </Wrapper>
                  </AnimatePop>
                </CTAContent>
              )}
              <AnimateWrapperBottom>
                {isMobile ? (
                  <AnimateSlideLeft>
                    <ItemsImg
                      src={
                        isMobile
                          ? Leaf2Reverse
                          : "https://storage.googleapis.com/whitelabel-set-live.appspot.com/678-mcdonalds-dannylux/Leaf2.png"
                      }
                      alt="sunglasses and other beach things"
                    />
                  </AnimateSlideLeft>
                ) : (
                  <AnimateSlideRight>
                    <ItemsImg
                      src={
                        isMobile
                          ? Leaf2Reverse
                          : "https://storage.googleapis.com/whitelabel-set-live.appspot.com/678-mcdonalds-dannylux/Leaf2.png"
                      }
                      alt="sunglasses and other beach things"
                    />
                  </AnimateSlideRight>
                )}
                <AnimateRotate
                  style={{
                    alignSelf: "flex-start",
                    zIndex: 100,
                  }}
                  left={true}
                >
                  <ItemsImg
                    style={{
                      margin: "70px 0 -60px",
                      width: isMobile && "150px",
                    }}
                    src={Starfish2}
                    alt="star"
                  />
                </AnimateRotate>
              </AnimateWrapperBottom>
            </CTA>

            <HeroImg
              onLoad={() => setLoaded(true)}
              isMobile={isMobile}
              src={isMobile ? imgHeroMob : imgHero}
            />
            {loaded && (
              <Hero>
                <CTABottom>
                  <AnimatePop>
                    <Wrapper download={!isMobile}>
                      {isMobile ? (
                        <SubHeading
                          style={{
                            textAlign: "center",
                            padding: 20,
                            maxWidth: 500,
                            marginTop: 30,
                          }}
                        >
                          Descarga la App de McDonald's y refréscate con un
                          Frozen.
                        </SubHeading>
                      ) : (
                        <SubHeading>
                          Descarga la App <br />
                          de McDonald's y <br /> refréscate con <br />
                          un Frozen.
                        </SubHeading>
                      )}

                      <StyledButton
                        download={true}
                        onClick={() => {
                          window.open(downloadLink, "_blank");
                          linkClickHandler(
                            downloadLink,
                            "Download app",
                            "external_link"
                          );
                        }}
                      >
                        Descargar
                      </StyledButton>
                    </Wrapper>
                  </AnimatePop>
                </CTABottom>
                <AnimationContainer>
                  <DrinksLeft>
                    <AnimateSlideRight>
                      <StyledImg src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/678-mcdonalds-dannylux/drinksLeft.png" />
                    </AnimateSlideRight>
                  </DrinksLeft>
                  <DrinksRight>
                    <AnimateSlideLeft>
                      <StyledImg src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/678-mcdonalds-dannylux/drinksRight.png" />
                    </AnimateSlideLeft>
                  </DrinksRight>
                </AnimationContainer>
                <ImageWrapperHero>
                  <ItemsImg
                    style={{
                      width: "100px",
                      margin: isMobile ? "-150px 0 150px -30px" : "0",
                    }}
                    src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/678-mcdonalds-dannylux/Starfish1.png"
                    alt="star"
                  />
                  <ItemsImg
                    style={{
                      width: isMobile && "150px",
                    }}
                    src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/678-mcdonalds-dannylux/Leaf2-reverse.png"
                    alt="star"
                  />
                </ImageWrapperHero>
                <img
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    position: "absolute",
                    left: 0,
                    bottom: 0,
                    right: 0,
                  }}
                  src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/678-mcdonalds-dannylux/wave.png"
                  alt="wave"
                />
              </Hero>
            )}

            {loaded && (
              <Footer>
                {footerHeading && (
                  <SubHeadingPostCampaign>
                    {ReactHtmlParser(footerHeading)}
                  </SubHeadingPostCampaign>
                )}
                {maxPolicy && (
                  <SubHeadingPostCampaign>
                    {ReactHtmlParser(maxPolicy)}
                  </SubHeadingPostCampaign>
                )}
                <CookieButton
                  onClick={() => {
                    if (window.Cookiebot) {
                      window.Cookiebot.renew();
                    }
                  }}
                >
                  Click Here to set Cookie Preferences.
                </CookieButton>
              </Footer>
            )}
          </Content>
        </Container>

        <Modal open={open} setOpen={setModalVisible}>
          <Media16By9 play="true">
            <MediaVideo
              src={video}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </Media16By9>
        </Modal>
      </Layout>
    );
  }
);

const Container = styled(MaxWidthContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  z-index: 2;
  max-width: 1500px;

  @media (max-width: 950px) {
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 950px) {
  }

  @media (max-width: 950px) {
  }
`;

const HeroImg = styled.img`
  width: 100%;
  object-fit: cover;
  z-index: 40;
  margin: -90px 0 0px 0;

  @media (max-width: 530px) {
    margin: -60px 0;
  }
  @media (min-width: 530px) and (max-width: 950px) {
    margin: -110px 0;
  }
`;
const ItemsImg = styled.img`
  width: 100%;
  object-fit: cover;
  z-index: 20;
`;
const AnimationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: -200px 0px 60px -160px;
  z-index: 70;
  @media (max-width: 750px) {
    margin: 0px auto;
    padding-bottom: 50px;
    padding-left: 40px;
  }
  @media (min-width: 751px) and (max-width: 950px) {
    margin: 0px 0 0 100px;
    padding-bottom: 50px;
  }
`;
const DrinksLeft = styled.div`
  width: 40%;
  z-index: 70;
  margin-left: 26%;
  @media (max-width: 950px) {
    margin: -16%;
    width: 83%;
  }
`;
const DrinksRight = styled.div`
  width: 43%;
  margin-left: -41%;
  z-index: 60;
  @media (max-width: 950px) {
    margin-left: -66%;
    width: 85%;
  }
`;
const StyledImg = styled.img`
  object-fit: cover;
  width: 100%;
`;

const CTA = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  position: relative;
  background-color: #fed82e;
  @media (max-width: 950px) {
    padding-bottom: 70px;
  }
`;
const Hero = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 50;
  background-color: #fed82e;
  padding-bottom: 60px;
  @media (max-width: 950px) {
    flex-direction: column;
    z-index: 30;
    padding-bottom: 60px;
  }
`;
const CTABottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  margin: 30px -140px 110px 200px;
  @media (max-width: 950px) {
    width: 100%;
    margin: 30px;
    justify-content: center;
    align-items: center;
  }
`;

const CTAContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: -100px;
  padding: 0 150px 10px;
  z-index: 150;

  p {
    text-align: center;
  }
  @media (max-width: 950px) {
    padding: 20px;
  }
`;
const AnimateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  margin: 0 auto 0 0;
`;
const AnimateWrapperBottom = styled.div`
  display: flex;
  width: 40%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: -100px auto 0 0;

  @media (max-width: 950px) {
    flex-direction: row-reverse;
    width: 100%;
    margin: -100px 0 -50px 0;
    justify-content: space-between;
  }
`;

const ImageWrapperHero = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 15%;
  margin: 0 0 0 auto;
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: 20%;
  top: 70%;
  @media (max-width: 950px) {
    flex-direction: row;
    width: 100%;
    margin: 0;
    left: 0;
    justify-content: space-between;
  }
`;
const Motiondiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 10;
  p {
    text-align: center;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${({ download }) =>
    download &&
    css`
      align-items: flex-start;
    `}/* @media (max-width: 950px) {
    width: 100%;
    text-align: center;
    margin: 40px auto;
  } */
`;

const VideoContainer = styled(VideoPlayer)`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;

  video {
    object-fit: cover;
  }
`;

const LogoBox = styled.div`
  margin: 50px;
  display: flex;
  width: 100%;
  max-width: 70px;
  align-self: center;

  @media (max-width: 950px) {
    margin: 0;
  }
`;

const Footer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin: 0 30px;
  padding: 30px;
  width: 100%;
  text-align: center;

  @media (max-width: 950px) {
    margin: 0;
    padding: 20px;
    width: 95%;
  }
`;

const StyledLink = styled.a`
  max-width: 100px;

  @media (max-width: 950px) {
    max-width: 50px;
  }
`;

const StyledButton = styled(ButtonPrimary)`
  width: 40%;
  max-width: 350px;
  height: 45px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 7px 1px 12px 1px; */
  margin: 30px auto -10px;
  border-radius: 3px;

  ${(props) =>
    props.download && "width: 270px; margin: 20px 0 0; align-self: center"}

  @media (max-width: 950px) {
    font-size: 1rem;

    width: 270px;
  }

  & > a {
    color: black;
    text-decoration: none;
  }
`;

const Logo = styled.img`
  width: 100%;
  display: flex;
`;

const Heading = styled.h2`
  font-size: min(3.5vw, 60px);
  margin-bottom: 10px;

  @media (max-width: 950px) {
    font-size: 40px;
  }
`;

const SubHeading = styled.h3`
  font-size: min(3vw, 45px);
  margin-bottom: 0px;

  @media (max-width: 950px) {
    font-size: max(3vw, 30px);
  }
`;

const Body = styled.p`
  color: black;
  font-size: min(1.2vw, 16px);
  line-height: 140%;
  display: block;
  width: 70%;
  text-align: left;

  @media (max-width: 950px) {
    padding: 0 50px;
    font-size: 0.8rem;
    line-height: 150%;
    width: 100%;

    div {
      font-size: 1rem;
      text-decoration: underline;
    }
  }

  & > span {
    display: inline-block;
    font-size: 1.4rem;

    @media (max-width: 950px) {
      font-size: 1rem;
    }
  }
`;

const SubHeadingPostCampaign = styled.h3`
  font-weight: 200;
  font-size: 16px;
  color: black;
  text-align: left;
  margin-bottom: 5px;
  font-family: "Speedee_RegCd";

  @media (max-width: 950px) {
    text-align: center;
    font-size: 16px;
    font-weight: 100;
  }

  & > a {
    color: black;
  }
`;
